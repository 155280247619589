// 2021
.bg-opacity { 
	background-color: rgba(255, 255, 255, 0.75);
	@media (max-width: 1024px) {
		position: relative !important;
	}
	
}
#panel-welcome {
	@media (max-width: 1280px) {
		.py-5{
			padding-top: 18px !important;
			padding-bottom: 18px !important;
		} 
		.py-4 {
			padding-top: 5px !important;
			padding-bottom: 5px !important;
		} 
		.logo-full {
			padding-top: 0 !important;
		}
	}
}
.bg-img-gray-light {
	filter: grayscale(100%);
	// -webkit-filter: grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
	filter: gray;
	opacity: .2;
}

// Typography
.display-4 {
	color: @st-blue;
	font-size: 5rem;
	line-height: 1.3;
    font-weight: 200;
}

// Flow Helpers

// Functions
// Spacers
@spacer : 1rem;

@spacers: {
	0:  0;
	1:  @spacer * 0.25;
	2:  @spacer * 0.5;
	3:  @spacer;
	4:  @spacer * 1.5;
	5:  @spacer * 3;
	6:  @spacer * 4.25;
	7:  @spacer * 5.75;
	8:  @spacer * 7;
	9:  @spacer * 8;
	10: @spacer * 9.5;
}

each(@spacers, {
    .top-@{key} {
        top: @value !important;
    }
	.left-@{key} {
        left: @value !important;
    }
	.right-@{key} {
        right: @value !important;
    }
	.bottom-@{key} {
        bottom: @value !important;
    }
	.p-@{key} {
        padding: @value !important;
    }
	.pl-@{key} {
        padding-left: @value !important;
    }
	.pr-@{key} {
        padding-right: @value !important;
    }
	.px-@{key} {
        padding-left: @value !important;
		padding-right: @value !important;
    }
	.pt-@{key} {
        padding-top: @value !important;
    }
	.pb-@{key} {
        padding-bottom: @value !important;
    }
	.py-@{key} {
        padding-top: @value !important;
		padding-bottom: @value !important;
    }
});

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}
.m-auto {
	margin: auto;
}

// Positions
@positions: {
	relative:  relative;
	static:    static;
	absolute:  absolute;
	fixed:     fixed;
	sticky:    sticky;
}

each(@positions, {
	.position-@{value} {
		position: @key;
	}
});

// Z Index
each(range(10), {
	.z-index-@{value} {
		z-index: @value;
	}
});

// Fade Carousel
.carousel-inner > .item > img, .carousel-inner > .item > a > img {
	display: block;
	height: auto;
	width: 100%;
	line-height: 1;
}

/*Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
CSS from:       http://codepen.io/transportedman/pen/NPWRGq
and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
Inspired from:  http://codepen.io/Rowno/pen/Afykb*/
.carousel-fade .carousel-inner .item {
	opacity: 0;
	transition-property: opacity;
}    
.carousel-fade .carousel-inner .active {
	opacity: 1;	
}    
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	left: 0;
	opacity: 0;
	z-index: 1;
}    
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}    
.carousel-fade .carousel-control {
	z-index: 2;
}
.carousel-fade .carousel-indicators {
	> li {
		margin: 3px 7px;
		background-color: rgba(255, 255, 255, 0.6);
		border: 0 !important;
		&.active {
			background-color: @st-red;
		}
	}
}

.carousel-fade .carousel-text {
	.bubble {
		font-weight: 400; 
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iODAiIGhlaWdodD0iODAiIHZpZXdCb3g9IjAgMCAyNTAgMjgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgoJPHBvbHlnb24gZmlsbD0iI2UwMTgzNyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBwb2ludHM9Ii0wLjUsMjUzLjUgLTAuNSwwLjUgMjQ5LjUsMC41IDI0OS41LDI3OS44IDIwMy4zLDI1My41Ij48L3BvbHlnb24+Cjwvc3ZnPg==); 
		background-size: contain; 
		width: 90px; 
		height: 90px; 
		line-height: 84px;
		margin-right: 5px;
		background-repeat: no-repeat;
	}
	.carousel-heading {
		border: 10px solid #fff;
		line-height: 1;
		@media (max-width: 1024px) {
			font-size: 24px !important
		}
	}
	.carousel-title {
		font-weight: 200; 
		text-transform: none;
		font-family: Avenir,Helvetica,Arial,sans-serif; 
		padding: 3px 0 0 10px;
		@media (max-width: 1024px) {
			font-size: 24px !important
		}
	}
}

/*WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
Need to override the 3.3 new styles for modern browsers & apply opacity*/
@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-fade .carousel-inner > .item.next,
	.carousel-fade .carousel-inner > .item.active.right {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
	}
	.carousel-fade .carousel-inner > .item.prev,
	.carousel-fade .carousel-inner > .item.active.left {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
	}
	.carousel-fade .carousel-inner > .item.next.left,
	.carousel-fade .carousel-inner > .item.prev.right,
	.carousel-fade .carousel-inner > .item.active {
	opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
	}
}

.d-flex {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	text-decoration: none !important;
	&.flex-column {
		flex-direction: column;
	}

}