// @import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=22fb2f97-5378-4fd3-833c-abd7d615e741");

@font-face {
	font-family:"Avenir";
	src:url("/fonts/3511a780-31ca-4ee1-9083-89b1b7185748.eot?#iefix");
	src:url("/fonts/3511a780-31ca-4ee1-9083-89b1b7185748.eot?#iefix") format("eot"),
		url("/fonts/c4352a95-7a41-48c1-83ce-d8ffd2a3b118.woff") format("woff"),
		url("/fonts/3e419b5b-c789-488d-84cf-a64009cc308e.ttf") format("truetype"),
		url("/fonts/ca038835-1be3-4dc5-ba25-be1df6121499.svg#ca038835-1be3-4dc5-ba25-be1df6121499") format("svg");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/6500273c-8e8b-48d2-b0c0-dcae293c0120.eot?#iefix");
	src:url("/fonts/6500273c-8e8b-48d2-b0c0-dcae293c0120.eot?#iefix") format("eot"),
		url("/fonts/47fd1045-6e5b-4c87-9235-cfbc2b42cde7.woff") format("woff"),
		url("/fonts/60662abb-0ac3-46b2-930f-4719462489c9.ttf") format("truetype"),
		url("/fonts/9cf39ac2-87a5-4c4e-a604-7cece19d30bd.svg#9cf39ac2-87a5-4c4e-a604-7cece19d30bd") format("svg");
	font-weight: 200;
	font-style: oblique;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix");
	src:url("/fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix") format("eot"),
		url("/fonts/939cba03-5b40-4d01-9bc5-7589eca863db.woff") format("woff"),
		url("/fonts/849bc5b9-a2ff-4343-977b-26ba8bd24a60.ttf") format("truetype"),
		url("/fonts/f67fa3b5-c1d1-488f-8e60-a828b9ad56a4.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4") format("svg");
	font-weight: normal;
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix");
	src:url("/fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix") format("eot"),
		url("/fonts/939cba03-5b40-4d01-9bc5-7589eca863db.woff") format("woff"),
		url("/fonts/849bc5b9-a2ff-4343-977b-26ba8bd24a60.ttf") format("truetype"),
		url("/fonts/f67fa3b5-c1d1-488f-8e60-a828b9ad56a4.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4") format("svg");
	font-weight: normal;
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/acc13cdc-338a-43e6-a156-e54a4c87582d.eot?#iefix");
	src:url("/fonts/acc13cdc-338a-43e6-a156-e54a4c87582d.eot?#iefix") format("eot"),
		url("/fonts/ca94ed56-18fc-4856-940a-70a072607c7c.woff") format("woff"),
		url("/fonts/dd6da407-70fe-4aa3-a1c7-64f0cb086f01.ttf") format("truetype"),
		url("/fonts/c7f424be-90e1-45b8-b617-ee3589a859bd.svg#c7f424be-90e1-45b8-b617-ee3589a859bd") format("svg");
	font-weight: 300;
	font-style: oblique;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/2f5a6074-badc-4e08-83f1-ed67fe5c3d7c.eot?#iefix");
	src:url("/fonts/2f5a6074-badc-4e08-83f1-ed67fe5c3d7c.eot?#iefix") format("eot"),
		url("/fonts/b9ebb19d-88c1-4cbd-9baf-cf51972422ec.woff") format("woff"),
		url("/fonts/5ed4f98e-9044-4810-88ff-74d412c1351f.ttf") format("truetype"),
		url("/fonts/4cb16859-16ca-4342-b89c-292dc83266af.svg#4cb16859-16ca-4342-b89c-292dc83266af") format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/8f21e618-9282-4df1-b556-73ee82bdd673.eot?#iefix");
	src:url("/fonts/8f21e618-9282-4df1-b556-73ee82bdd673.eot?#iefix") format("eot"),
		url("/fonts/3695342c-b5e2-4010-b0d4-56f563465922.woff") format("woff"),
		url("/fonts/2ba9f875-66d9-414d-a426-5a012b443475.ttf") format("truetype"),
		url("/fonts/0a88351a-e628-4b1f-99eb-3a729518af0a.svg#0a88351a-e628-4b1f-99eb-3a729518af0a") format("svg");
	font-weight: 400;
	font-style: oblique;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/212ab03d-5db2-4d6a-b94c-171cc702aa51.eot?#iefix");
	src:url("/fonts/212ab03d-5db2-4d6a-b94c-171cc702aa51.eot?#iefix") format("eot"),
		url("/fonts/2cac77ec-9bc0-4ee7-87e4-27650190744f.woff") format("woff"),
		url("/fonts/aaf11848-aac2-4d09-9a9c-aac5ff7b8ff4.ttf") format("truetype"),
		url("/fonts/294099f3-8d00-4676-afc5-5e9d8d43ad69.svg#294099f3-8d00-4676-afc5-5e9d8d43ad69") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/2a6f81a2-475c-4831-9949-33d7748ee561.eot?#iefix");
	src:url("/fonts/2a6f81a2-475c-4831-9949-33d7748ee561.eot?#iefix") format("eot"),
		url("/fonts/e0af2f49-a399-482b-a54e-d745e268ec80.woff") format("woff"),
		url("/fonts/6803d3dd-2107-45fc-ac8a-a4fa13771547.ttf") format("truetype"),
		url("/fonts/1bb1acb5-8d4e-4800-88af-dbcf1fe96ef8.svg#1bb1acb5-8d4e-4800-88af-dbcf1fe96ef8") format("svg");
	font-weight:  normal;
	font-weight: 500;
	font-style: oblique;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?#iefix");
	src:url("/fonts/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?#iefix") format("eot"),
		url("/fonts/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff") format("woff"),
		url("/fonts/d1dc54b2-878d-4693-8d6e-b442e99fef68.ttf") format("truetype"),
		url("/fonts/731dd4d3-64da-427d-ba61-01575b3cf3f7.svg#731dd4d3-64da-427d-ba61-01575b3cf3f7") format("svg");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/7be0fdec-384a-42b5-ab27-c60a322cde7d.eot?#iefix");
	src:url("/fonts/7be0fdec-384a-42b5-ab27-c60a322cde7d.eot?#iefix") format("eot"),
		url("/fonts/53554f6f-4b01-4815-87fe-82c49b8ba5a9.woff") format("woff"),
		url("/fonts/28dfedaf-6372-45a1-8833-b3078dc56a21.ttf") format("truetype"),
		url("/fonts/f747b6ee-4d13-4e45-815d-f33ac1b71d63.svg#f747b6ee-4d13-4e45-815d-f33ac1b71d63") format("svg");
	font-weight: 800;
	font-style: oblique;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix");
	src:url("/fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix") format("eot"),
		url("/fonts/5a05cf7f-f332-456f-ae67-d8c26e0d35b3.woff") format("woff"),
		url("/fonts/c33278bc-988e-440b-b4be-6acc095e6044.ttf") format("truetype"),
		url("/fonts/7bdad4a5-289b-42f9-b6fa-c5883b9e9f23.svg#7bdad4a5-289b-42f9-b6fa-c5883b9e9f23") format("svg");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family:"Avenir";
	src:url("/fonts/fbf3e69b-73b2-406d-84bc-feda30a0a563.eot?#iefix");
	src:url("/fonts/fbf3e69b-73b2-406d-84bc-feda30a0a563.eot?#iefix") format("eot"),
		url("/fonts/36ec15b9-f8da-447f-8fed-a9cfdfb91fbb.woff") format("woff"),
		url("/fonts/230d6b14-6d44-4dd5-a6ae-a4287936e51e.ttf") format("truetype"),
		url("/fonts/9bce4920-94e2-4e4d-bd34-818801dd3eb3.svg#9bce4920-94e2-4e4d-bd34-818801dd3eb3") format("svg");
	font-weight: 900;
	font-style: oblique;
}

@font-face{
	font-family:"Avenir Next";
	src:url("/fonts/4064c113-1653-48f1-a2e8-e7eaf6f66870.eot?#iefix");
	src:url("/fonts/4064c113-1653-48f1-a2e8-e7eaf6f66870.eot?#iefix") format("eot"),
		url("/fonts/984b2eaf-3cdc-4198-93c9-3049f9ba0659.woff") format("woff"),
		url("/fonts/f2ac19d1-c658-4ad1-abc1-398d300c43c0.ttf") format("truetype"),
		url("/fonts/cada96b3-47b6-4b7e-8adf-3d40df8cd73b.svg#cada96b3-47b6-4b7e-8adf-3d40df8cd73b") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face{
	font-family:"Avenir Next";
	src:url("/fonts/4ca885ce-2ca8-4e29-9956-9cbb8959f689.eot?#iefix");
	src:url("/fonts/4ca885ce-2ca8-4e29-9956-9cbb8959f689.eot?#iefix") format("eot"),
		url("/fonts/50c9f3b3-2847-4045-815c-c4a3f76a3867.woff") format("woff"),
		url("/fonts/daa119e0-387f-4ea4-a3b5-f4015d8d3768.ttf") format("truetype"),
		url("/fonts/129e0ebd-34d0-491e-9b72-281cb707cb52.svg#129e0ebd-34d0-491e-9b72-281cb707cb52") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/b3e9f984-f8ca-4650-90c3-d6f0aca0a27d.eot?#iefix");
	src:url("/fonts/b3e9f984-f8ca-4650-90c3-d6f0aca0a27d.eot?#iefix") format("eot"),
		url("/fonts/b984f4e8-e37d-4502-bead-ffd991e64d1f.woff") format("woff"),
		url("/fonts/82d18baa-8811-4aa8-9338-9bafa08350d0.ttf") format("truetype"),
		url("/fonts/20e14bb9-6aec-47a9-8ef8-5613bf1d6eae.svg#20e14bb9-6aec-47a9-8ef8-5613bf1d6eae") format("svg");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/7f8bf145-43b4-4e18-8ab6-38552ac83b70.eot?#iefix");
	src:url("/fonts/7f8bf145-43b4-4e18-8ab6-38552ac83b70.eot?#iefix") format("eot"),
		url("/fonts/3efa8eef-d81a-4a5b-ba15-94c5afa463cf.woff") format("woff"),
		url("/fonts/0ac3a8d1-ea58-4bc8-b995-cd7fce51290e.ttf") format("truetype"),
		url("/fonts/f360572f-8d74-4e11-b63c-91a55e17c82d.svg#f360572f-8d74-4e11-b63c-91a55e17c82d") format("svg");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix");
	src:url("/fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix") format("eot"),
		url("/fonts/c5b1c170-d8f7-41f9-85c2-0ab670780c6b.woff") format("woff"),
		url("/fonts/ba1ff8f6-0c27-4e7b-8be5-818b6c3dd801.ttf") format("truetype"),
		url("/fonts/a379413e-a0b8-44dc-b250-1e000e4092a4.svg#a379413e-a0b8-44dc-b250-1e000e4092a4") format("svg");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix");
	src:url("/fonts/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix") format("eot"),
		url("/fonts/7becdf28-cd45-4f8a-bcab-d8c861a8ebc5.woff") format("woff"),
		url("/fonts/b90a1a2e-a700-4528-aa00-535c93ecf8a9.ttf") format("truetype"),
		url("/fonts/85c745b1-d826-4e09-988f-82cd152fb0db.svg#85c745b1-d826-4e09-988f-82cd152fb0db") format("svg");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix");
	src:url("/fonts/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix") format("eot"),
		url("/fonts/c22866d7-ca67-4170-a113-cae280eea669.woff") format("woff"),
		url("/fonts/19ce9ea9-076e-4dcd-91a1-454f4830f120.ttf") format("truetype"),
		url("/fonts/18915124-98e4-4245-9e10-b921e09a6704.svg#18915124-98e4-4245-9e10-b921e09a6704") format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/e3c5d5a6-3f08-4d0c-a4af-3d90f6fa03ee.eot?#iefix");
	src:url("/fonts/e3c5d5a6-3f08-4d0c-a4af-3d90f6fa03ee.eot?#iefix") format("eot"),
		url("/fonts/f83744dc-5c76-4309-bea0-03c18f58997f.woff") format("woff"),
		url("/fonts/dc5e0550-21b2-4664-a54a-248791c96660.ttf") format("truetype"),
		url("/fonts/f1682532-45c5-4c1b-a058-a65e600e0100.svg#f1682532-45c5-4c1b-a058-a65e600e0100") format("svg");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/4f2af761-c017-4801-9aa5-5d30643373ce.eot?#iefix");
	src:url("/fonts/4f2af761-c017-4801-9aa5-5d30643373ce.eot?#iefix") format("eot"),
		url("/fonts/acfb7490-30f6-4bf4-a1b8-70ff9172d209.woff") format("woff"),
		url("/fonts/5bf402e3-ba96-414e-bc7e-cb6689fe32c1.ttf") format("truetype"),
		url("/fonts/c5dceae7-427a-4765-b06b-ea2eecca3434.svg#c5dceae7-427a-4765-b06b-ea2eecca3434") format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/67452424-3136-49b1-827d-49c5dcde5779.eot?#iefix");
	src:url("/fonts/67452424-3136-49b1-827d-49c5dcde5779.eot?#iefix") format("eot"),
		url("/fonts/088bed5d-3869-422a-8876-df9a6992b434.woff") format("woff"),
		url("/fonts/b0e5af12-5659-4451-b843-6fade25c4e53.ttf") format("truetype"),
		url("/fonts/e590a993-9202-42bc-a22f-25243e6b0d5a.svg#e590a993-9202-42bc-a22f-25243e6b0d5a") format("svg");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/a8a5b7cb-4d2f-479b-9437-689fc5435c69.eot?#iefix");
	src:url("/fonts/a8a5b7cb-4d2f-479b-9437-689fc5435c69.eot?#iefix") format("eot"),
		url("/fonts/343cca80-f864-4475-9357-bd86359efe6d.woff") format("woff"),
		url("/fonts/14f45cdf-4558-43a1-b76b-abe9a1d84c4a.ttf") format("truetype"),
		url("/fonts/6f13d7fa-3aaf-46ea-995a-89d7fb422896.svg#6f13d7fa-3aaf-46ea-995a-89d7fb422896") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family:"Myriad";
	src:url("/fonts/44fe289c-a576-4f24-b668-a62cf2fdcb9c.eot?#iefix");
	src:url("/fonts/44fe289c-a576-4f24-b668-a62cf2fdcb9c.eot?#iefix") format("eot"),
		url("/fonts/d89bc622-54c7-43da-91ad-a74f625a1e02.woff") format("woff"),
		url("/fonts/50d1ea1c-06bb-40c8-8c9f-caa23557d59d.ttf") format("truetype"),
		url("/fonts/daabede8-40a2-40d6-968d-4359952e67e4.svg#daabede8-40a2-40d6-968d-4359952e67e4") format("svg");
	font-weight: 500;
	font-style: italic;
}