@import "./bower/bootstrap/less/bootstrap";
@import "./bower/font-awesome/less/font-awesome";
@import "./bower/jasny-bootstrap/less/jasny-bootstrap";
@import "variables";
@import "column-border";
@import "fonts";
@import "revamp";
@import "ray-of-light";


.box.box-social {
	text-align: center;
}
.timeline-Footer {
	display: none !important;
}
h1.box-banner {
	font-size: 20px;
	background: #002458;
	padding: 10px;
	color: white;
	margin:0;
}

.preloader {
	background-image: url('../img/preloader.gif');
	width: 60px;
	height: 47px;
	margin: 0 auto;
	background-repeat: no-repeat;
}
.bottom-pad{
	margin-bottom:210px;
}

.barometer {
	width: 100px;
	position: relative;
}

.barometer .mercury {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	background-color: #002458;

}
.barometer .img {
	position: relative;
	z-index: 1;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

@media  (min-width: @screen-xs) and (max-width: @screen-xs-max) {
	.col-xs-sm-6 {
		float: left;
		width: percentage((6 / @grid-columns));
	}

	.xs-clearfix {
		.clearfix;
	}
}

.img-responsive {
	width: 100%;
}

.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }
.visuallyhidden {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }

.invalid-feedback { display: none; margin-top:  @padding-small-vertical; }
.is-invalid ~ .invalid-feedback { display: block; }
.form-control.is-invalid { border-color: @brand-danger; }


.mixin-loop (@i) when (@i >= 0) {
	.mt-@{i} {
		margin-top: @line-height-computed * @i !important;
	}

	.mt-n@{i} {
		margin-top: -1 * (@line-height-computed * @i) !important;
	}

	.mb-@{i} {
		margin-bottom: @line-height-computed * @i !important;
	}
	.ml-@{i} {
		margin-left: @line-height-computed * @i !important;
	}
	.mr-@{i} {
		margin-right: @line-height-computed * @i !important;
	}
	.my-@{i} {
		margin-top: @line-height-computed * @i !important;
		margin-bottom: @line-height-computed * @i !important;
	}
	.mx-@{i} {
		margin-left: @line-height-computed * @i !important;
		margin-right: @line-height-computed * @i !important;
	}
	.m-@{i} {
		margin: @line-height-computed * @i !important;
	}
	.pt-@{i} {
		padding-top: @line-height-computed * @i !important;
	}
	.pb-@{i} {
		padding-bottom: @line-height-computed * @i !important;
	}
	.pl-@{i} {
		padding-left: @line-height-computed * @i !important;
	}
	.pr-@{i} {
		padding-right: @line-height-computed * @i !important;
	}
	.py-@{i} {
		padding-top: @line-height-computed * @i !important;
		padding-bottom: @line-height-computed * @i !important;
	}
	.px-@{i} {
		padding-left: @line-height-computed * @i !important;
		padding-right: @line-height-computed * @i !important;
	}
	.p-@{i} {
		padding: @line-height-computed * @i !important;
	}

	@media (min-width: @screen-md){
		.p-md-@{i} {
			padding: @line-height-computed * @i !important;
		}
	}

	.mixin-loop(@i - 1);
}

.spacing (@i) when (@i >= 0) {
	@s: @i * 100;
	.mt-0@{s} {
		margin-top: @line-height-computed * @i !important;
	}
	.mb-0@{s} {
		margin-bottom: @line-height-computed * @i !important;
	}
	.ml-0@{s} {
		margin-left: @line-height-computed * @i !important;
	}
	.mr-0@{s} {
		margin-right: @line-height-computed * @i !important;
	}
	.my-0@{s} {
		margin-top: @line-height-computed * @i !important;
		margin-bottom: @line-height-computed * @i !important;
	}
	.mx-0@{s} {
		margin-left: @line-height-computed * @i !important;
		margin-right: @line-height-computed * @i !important;
	}
	.m-0@{s} {
		margin: @line-height-computed * @i !important;
	}
	.pt-0@{s} {
		padding-top: @line-height-computed * @i !important;
	}
	.pb-0@{s} {
		padding-bottom: @line-height-computed * @i !important;
	}
	.pl-0@{s} {
		padding-left: @line-height-computed * @i !important;
	}
	.pr-0@{s} {
		padding-right: @line-height-computed * @i !important;
	}
	.py-0@{s} {
		padding-top: @line-height-computed * @i !important;
		padding-bottom: @line-height-computed * @i !important;
	}
	.px-0@{s} {
		padding-left: @line-height-computed * @i !important;
		padding-right: @line-height-computed * @i !important;
	}
	.p-0@{s} {
		padding: @line-height-computed * @i !important;
	}

	@media (min-width: @screen-md){
		.p-md-0@{s} {
			padding: @line-height-computed * @i !important;
		}
	}

	.spacing(@i - 0.25);
}

.mixin-loop (0) {}
.mixin-loop(3);
.spacing(0.75);


.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
}

.translate-y-n50 {
	transform: translateY(-50%);
}

// Floats
.float-right {
	float: right;
	margin: 0 0 @line-height-computed @line-height-computed;
	clear: right; // This ensures images don't 'bump' into each other
}

.float-left {
	float: left;
	margin: 0 @line-height-computed @line-height-computed 0;
	clear: left; // This ensures images don't 'bump' into each other
}

.float-center {
	float: none;
	margin: 0 auto;
}

.float-none {
	float: none; // if anyone knows why these are "important", let me know
	margin: 0;
}

.clear-both {
	clear: both;
}

// Useful for image link hover states
.transparent (@filter-opacity, @opacity) {
	zoom: 1;
	filter: alpha(opacity=@filter-opacity);
	opacity: @opacity;
}

/**	----------------------------------------
/**	Environment Indicator
/**	----------------------------------------*/
.env-indicator {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 99;
	color: #fff;
	border-radius: 5px 0 0 0;
	padding: 2px 5px;
	font-size: 11px;
}

.env-indicator.env-dev {
	background: @state-success-text;
}

.env-indicator.env-staging {
	background: @state-warning-text;
}


.btn-edit-this {
	font-size: 14px !important;
	z-index: 99;
	
	.btn;
	.btn-default;
	.fa;
	padding: 5px 6px;

	&:before {
		content: @fa-var-pencil;
	}

	position: absolute;
	top: 0px;
	left: 0px;
}


/**	----------------------------------------
/** STRUCTURE
/**	1. Layout & Structure
/**	2. Modular components
/**	3. Page Specific
/**	----------------------------------------

/**	----------------------------------------
/**	3. LAYOUT & STRUCTURE
/** Page structures & layout
/**	----------------------------------------*/
html {
	overflow-y: scroll;
}

html,body {
	height: 100%;
	position: relative;
}

.navmenu {
	border: 0;
}

.navmenu-brand {
	padding: 0 20px;
	margin: 0;

	.return-home {
		background-color: lighten(@gray, 10%);
		font-family: @font-family-sans-serif-alt;
		text-transform: uppercase;
		color: @white;
		padding: 21px 25px;
		margin-left: -20px;
		margin-right: -20px;
		
		font-size: 15px;
	}

	img {
		margin-top: 55px;
		margin-bottom: 55px;
	}
}

.navmenu-nav {
	font-size: @font-size-small;
	line-height: 13px;
	text-transform: uppercase;
	letter-spacing : 0.6px;

	.dropdown-indicator {
		right: 10px;
		position: absolute;
		color: @white;

		&:before {
			content: @fa-var-caret-down;
		}
	}

	.dropdown.open {
		.dropdown-backdrop {
			display: none;
			position: static;
			z-index: -999;
		}

		.dropdown-indicator {
			&:before {
				content: @fa-var-times;
			}
		}
	}

	> li {
		border-top: @gray-light 1px solid;

		> a {
			padding: 15px 10px 15px 25px;

			&.root {
				padding: 0;
			}
		}

		> ul {
			> li {
				> a {
					padding: 5px 5px 5px 45px;
				}
			}
		}

		&.school-label {
			.block {
				display: inline-block;
				font-family: @font-family-base;
				border: 1px transparent solid;
				font-size: 12px;
				line-height: 1;
				padding: 6px 0;
				text-align: center;
				width: 25px;
				background-color: @st-red;
				color: @white;
			}

			.contents {
				display: inline-block;
				font-size: 14px;
			}
		}
	}

	.dropdown-menu {
		> li {
			> a {
				white-space: normal;
			}
		}
	}
}

.navbar-default {
	border: 0;
	text-align: right;

	.container {
		padding: 0px;
	}

	&.navbar {
		margin-bottom: 0px;
		min-height: 30px;
	}

	.navbar-nav {
		font-size: @font-size-small;
		text-transform: uppercase;
		margin: 0px;
		float: right;

		&.navbar-right {
			float: right;
		}


		> li {
			border-left: 1px @gray-light solid;
			float: left;


			> a {
				padding: 8px 15px;
			}
		}
	}

	.search-toggle {
		background: @gray-light;
		display: block;
		float: right;
		border: 0;
		color: @gray-dark;
		height: 40px;
		padding: 4px 8px;
		line-height: 2.2;
	}

	#search-box {
		display: block;
		float: right;
		overflow: hidden;
		background: @white;

		form {
			border: 0;
			padding: 0;
			margin: 0;
			height: 40px;

			.form-control {
				display: inline-block;
				background: transparent;
				width: 110px;
				border: 0;
				height: 40px;
				box-shadow: none;
			}

			.search-submit {
				display: inline-block;
				margin: 0px;
				padding: 4px 8px;
				border: 0;
				background: transparent;
				height: 40px;
				background: @gray-light;

				&:hover {
					background: @gray;
				}
			}
		}
	}
}

#wrap {
	min-height: 100%;
	height: auto;
	/* Negative indent footer by its height */
	margin: 0 auto;
	/* Pad bottom by footer height */
	padding: 0 0;
}

#header {
	margin: 0;
	background: @gray-light;
	padding-top: 24px;
	padding-bottom: 10px;
}

#header-logo {
	img {
		width: 232px;
		margin: 7px 0 6px 0;
	}
}

.burger-button {
	background-color: @st-red;
	color: @white;
	border: 0;
	padding: 5px 6px 2px 6px;

	.bars {
		display: block;
		height: 4px;
		margin-bottom: 3px;
		background-color: @white;
	}

	.burger-label {
		display: block;
		font-size: 14px;
		line-height: 1;
		font-family: @font-family-alt;
		margin-top: -2px;
	}

}

.school-button {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	// height: 26px;
	width: 30px;
	font-family: @font-family-alt;
	text-transform: uppercase;
	font-size: 12px;
	color: @gray;
	text-align: left;
	transition: width .5s ease-in-out .175s;
	overflow: hidden;
	white-space: nowrap;
	// position: relative;
	padding: 3px 0;

	.block {
		display: inline-block;
		vertical-align: middle;
		font-family: @font-family-base;
		// background-color: @st-red;
		// border: 1px @gray solid;
		font-size: 12px;
		line-height: 1;
		padding: 6px 0;
		width: 30px;
		text-align: center;
		color: @white;
	}

	.contents {
		display: inline-block;
		vertical-align: middle;
		margin-left: 2px;
		margin-bottom: 1.5px;
	}
}


.school-button:hover, .school-button.active {
	width: 135px;
	text-decoration: none;
	color: @gray;
	transition: width .5s ease-in-out .175s;
}

#school-menu {
	display: none;
	font-family: @font-family-alt;
	text-transform: uppercase;
	font-size: 11px;
	line-height: 1;
	
	color: @gray;

	list-style: none;
	text-align: right;
	margin: 0;
	padding: 0;

	> li {
		list-style: none;
		display: inline-block;
		vertical-align: middle;
		.schools-label {
			display: inline-block;
			height: 26px;
			font-family: @font-family-alt;
			text-transform: uppercase;
			font-size: 11px;
			color: @gray;
			text-align: left;
			overflow: hidden;
			white-space: nowrap;

			.block {
				display: inline-block;
				font-family: @font-family-base;
				border: 1px transparent solid;
				font-size: 12px;
				line-height: 1;
				padding: 6px 0 6px 0;
				width: 0px;
				text-align: center;
			}

			.contents {
				display: inline-block;
			}
		}
	}
}

.circled-icon {
	color: #fff;
	background-color: @st-blue;
	display: inline-block;
	border-radius: 50%;
	height: 45px;
	width: 45px;
	margin: 10px;
	text-align: center;
	opacity: 0.75;
	i {
		color: #fff;
		line-height: 45px;
	}
	&:hover {
		background-color: @st-red;
		opacity: 1;
	}
}

#footer {
	background: @gray-dark;
	margin-top: 0px;
	width: 100%;

	.social-footer {
		padding: 10px 0 5px;
		.circled-icon {
			// color: @gray-dark;
			// border-color: @gray-dark;
			margin: 6px;
		}
	}

	.contacts-footer {
		background: @gray-light;
		padding: 30px 0;
		font-size: 12px;
		color: @gray-dark;
		text-align: center;
		position: relative;

		h6 {
			font-size: 14px;
			margin: 5px 0 0;
		}

		a {
			color: @gray-dark;
		}

		.contact-item {
			padding-top: 10px;
		}
	}

	.partners-footer {
		background: @white;
		padding: 30px 0;
		text-align: center;

		> div {
			position: relative;
		}

		img {
			padding: 0 5px;
			height: 45px;
		}

		ul {
			margin: 30px 0 0 0;
			padding: 0;
			font-family: @font-family-alt;
			text-transform: uppercase;

			li {
				margin: 0;
				padding: 0;
				list-style: none;
				display: inline-block;
				padding: 0 10px;

			}
		}
		a:hover, a:focus{
			text-decoration:none;
		}
	}

	.whois-footer {
		text-align: center;
		padding: 15px;
		color: @white;

		a {
			color: @white;
		}
	}

	@media (min-width: @screen-sm) {
		.contacts-footer {
			text-align: left;

			.contact-item {
				margin-top: 0;
			}
		}
	}

	@media (min-width: @screen-md) {
		.social-footer {
			.circled-icon {
				margin: 0 6px;
			}
		}

		.contacts-footer {

			.tel-cta {
				text-decoration: none;
				cursor: default;
			}
		}

		.partners-footer {
			img {
				padding: 0 10px;
				height: 65px;
			}
		}
	}
}


#sidebar {
	.caption {
		margin-top: 0px;
		margin-bottom: 40px;
		text-transform: uppercase;
	}

	h2 {
		font-weight: 200;
		margin-top: 0;
		font-size: 20px;
	}

	.social-share-list {
		.btn {
			padding: 6px 6px;
		}
	}

	.nav-pills {
		border-top: 1px @gray-light solid;

		li {
			border-bottom: 1px @gray-light solid;

			a {
				color: @gray-dark;
			}
		}

		&.nav-stacked {
			> li + li {
				margin: 0;
			}
		}
	}

	@media (min-width: @screen-md) {
		.social-share-list {
			.btn {
				padding: 6px 12px;
			}
		}
	}
}

.section-indicator {
	position: absolute;
	left: 15px;

	.label {
		position: absolute;
		font-size: 12px;
		font-family: @font-family-sans-serif;
		top: 0;
		width: 25px;
		padding: 0;
		line-height: 26px;
		font-variant: 300;
		left:0;
	}
}


// HOME PAGE
// ------------------------------------

#panel-welcome,
#panel-static,
#panel-message,
#panel-schools,
#panel-quote,
#panel-news,
#panel-promo,
#panel-map {
	position: relative;

	h1.panel-title {
		color: @st-blue;
		text-align: center;
		font-family: @font-family-sans-serif-alt;
		margin: 10px 0 30px 0;
		font-size: 36px;
	}
}

#panel-welcome {
	background-color: @gray-light;
	background-image: url('/uploads/files/panel-welcome-v2.jpg');
	background-position: center center;
	background-size: cover;
	padding: 0;

	h2, h1 {
		color: @black;
		text-transform: uppercase;
		font-family: @font-family-sans-serif;
	}

	h2 {
		font-size: 19px;
		font-weight: 300;
		margin: 0;
	}

	h1 {
		font-size: 27px;
		font-weight: 900;
		margin: 0 0 18px;
	}

	.tagline {
		display: inline;
		background: @st-red;
		color: @white;
		text-transform: uppercase;
		font-weight: 200;
		font-size: 14px;
		line-height: 1.2;
		padding: 5px 0;
		> span {
			padding: 5px 10px;
		}

		&.tagline-half {
			display: none;
		}

		&.tagline-full {
			display: inline;
		}
	}

	.logo-crest {
		display: inline-block;
		height: 130px;
	}

	.logo-full {
		display: none;
		height: 130px;
	}

	.box {
		padding: 20px 15px 15px 55px; 
		background-color: @st-blue;
		margin-top: 25px;
		display: block;
		position: relative;
		cursor: pointer;
		
		&.box-blue {
			background-color: rgba(red(@st-blue), green(@st-blue), blue(@st-blue), .8);
			color: @white;

			.summary {
				color: @white;
			}

			&:after {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 40px 40px;
				border-color: transparent transparent @st-blue transparent;
				bottom: 0;
				right: 0;
			}

			&:hover {
				text-decoration: none;

				&:after {
					border-color: transparent transparent @st-red transparent;
				}
			}
		}

		h2 {
			color: #fff;
			margin-bottom: 5px;
			font-family: @headings-font-family;
			text-transform: none;
			font-size: 26px;
			font-weight: 100;

			b {
				font-weight: 300;
			}

			.section-indicator {
				.bubble {
					polygon {
						fill: @st-red;
					}

					path {
						fill: @white;
					}

				}
			}

		}

		h3 {
			margin: -8px 0 10px 0;
			font-size: 17px;
			font-weight: 100;
		}

		.summary {
			font-size: 14px;
			line-height: 18px;
		}

	}

	@media (min-width: 460px) {
		.tagline {
			font-size: 18px;

			&.tagline-half {
				display: inline;
			}

			&.tagline-full {
				display: none;
			}
		}
	}

	@media (min-width: @screen-sm) {
		.logo-crest {
			display: none;
		}

		.logo-full {
			display: inline-block;
		}
	}

	@media (min-width: @screen-md) {
		h1 {
			font-size: 33px;
		}

		.tagline {
			font-size: 16px;
		}
	}
}

#panel-static {
	padding-top: 40px;
	padding-bottom: 40px;
}

#panel-message {
	background-color: @gray-lighter;
	padding-top: 30px;
	padding-bottom: 30px;

	.profile-image {
		position: relative;
		display: none;
		height: 100%;
		min-height: 300px;

		img {
			width: 160%;
			position: absolute;
			bottom: -30px;
			left: -50%;
		}

		.caption {
			position: absolute;
			bottom: 0px;
			text-transform: uppercase;
			margin-bottom: -20px;
		}
	}

	h1 {
		font-size: 46px;
		font-family: @font-family-sans-serif;
		font-weight: normal;
	}

	@media (min-width: @screen-sm) {
		.profile-image {
			display: block;
		}
	}

	@media (min-width: @screen-md) {
		padding-top: 50px;
		padding-bottom: 50px;

		.profile-image {
			display: block;

			img {
				width: 125%;
				left: -80px;
				bottom: -50px;
			}

			.caption {
				right: 15px;
			}
		}
	}

	@media (min-width: @screen-lg) {
		.profile-image {
			img {
				width: 100%;
				left: 0;
			}
		}
	}
}

#panel-schools {
	// background-color: @gray-light;
	padding: 50px 0;
	display: none;

	#school-menu-large {
		margin: 0px;
		padding: 0px;
		list-style: none;

		> li {
			display: block;
			float: left;
			margin: 0;
			padding: 0;
			width: 20%;
			font-size: 13px;
			line-height: 1.2;

			a {
				color: @gray-dark;
				padding: 0 0 0 50px;
				display: inline-block;
				position: relative;
				text-transform: uppercase;

				.section-indicator {
					color: @st-blue;

					.bubble {
						width: 28px;
						height: 32px;

						path {
							fill: @st-blue;
						}

					}

					.label {
						color: @st-blue;
						width: 28px;
						line-height: 29px;
					}
				}

				&:hover {
					text-decoration: none;
					color: @st-blue;

					.section-indicator {
						.bubble {
							path {
								fill: @gray-dark;
							}
						}
						.label {
							color: @gray-dark;
						}
					}
				}
			}
		}
	}
}

@media (min-width: @screen-sm) {
	#panel-schools {
		display: block;
	}
}

@media (min-width: @screen-md) {
	#panel-schools {
		padding: 85px 0;

		#school-menu-large {
			> li {
				font-size: 17px;

				a {
					padding: 0 0 0 65px;

					.section-indicator {
						.bubble {
							width: 38px;
							height: 43px;
						}

						.label {
							width: 38px;
							line-height: 39px;
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: @screen-lg) {
	#panel-schools {

		#school-menu-large {
			> li {
				font-size: 22px;

				a {
					padding: 0 0 0 75px; //===

					.section-indicator {
						.bubble {
							width: 45px;
							height: 50px;
						}

						.label {
							width: 45px;
							line-height: 45px;
							font-size: 17px;
						}
					}
				}
			}
		}
	}
}

#panel-quote {

	.quote-background {
		background-color: @gray-light;
		background-size: cover;
		
		line-height: 1.3;
		color: @st-blue;
		text-align: center;

		padding: 50px 0;
		font-size: 40px;

		@media (min-width: @screen-sm) {
			padding: 80px 0;
			font-size: 42px;
		}

		@media (min-width: @screen-md) {
			padding: 110px 0;
			font-size: 44px;
		}

		@media (min-width: @screen-lg) {
			padding: 140px 0;
			font-size: 48px;
		}

		&.social{
			text-align: left;
			line-height: 13px;
			padding-top: 60px;

			.js-ajax-content{
				max-height: 345px;
				height: 345px;
				
				a{
					font-size: 14px;
				}	
			}

			h1.box-banner{
				font-weight: lighter;
			}
		}
	}

}

body.subsite-kamoka {

	.btn-danger {
		color: @white;
	}

	.caption {
		.name {
			background-color: rgba(red(@kamoka-green), green(@kamoka-green), blue(@kamoka-green), .8);
		}
	}

	#panel-welcome {
		padding: 15px 0;

		h2 {
			margin-top: 50px;
		}

		.tagline {
			background-color: @kamoka-green;
		}

		.logo-full {
			height: 200px;
		}
	}

	.summary {
		color: @kamoka-green;
	}

	#panel-quote {
		.quote-background {
			font-size: 30px !important;
			color: @black;

			a {
				color: @black;
			}
		}
	}
}

body.subsite-thandulwazi {

	.btn-danger {
		color: @white;
	}

	.caption {
		.name {
			background-color: rgba(red(@thand-blue-dark), green(@thand-blue-dark), blue(@thand-blue-dark), .8);
		}
	}

	#panel-welcome .tagline {
		background-color: @thand-blue-light;
	}

	.summary {
		color: @thand-blue-light;
	}
}

body.subsite-foundation {
	#panel-welcome {
		padding: 20px 0;

		h2 {
			margin-top: 40px;
		}

		.logo-full {
			height: 180px;
		}
	}
}

#panel-news {
	padding: 15px 0;
	
	@media (min-width: @screen-md) {
		padding: 50px 0;
	}

}

#panel-promo {
	.promo-link {
		display: block;
		background-color: @st-blue;
		background-size: cover;
		color: @white;
		line-height: 1.3;
		text-align: center;

		padding: 50px 0;
		font-size: 20px;



		b {
			color: @st-red;
		}

		.circled-icon {
			// color: @white;
			// border-color: @white;
		}
		
		@media (min-width: @screen-sm) {
			padding: 70px 0;
			font-size: 24px;
		}

		@media (min-width: @screen-md) {
			padding: 90px 0;
			font-size: 30px;
		}

		@media (min-width: @screen-lg) {
			padding: 110px 0;
			font-size: 34px;
		}
	}
}

#panel-map {
	#map-canvas {
		border: 0;
		display: block;
		width: 100%;
		height: 380px;
	}
}


/* ========================================= */

/**	----------------------------------------
/**	2. Modular components
/** Modular blocks & re-usable components
/**	----------------------------------------*/

// Media Cntd.
// ------------------------------------
.listing {
	margin-bottom: 40px;
	text-align: center;

	.listing-body {
		width: 100%;
		display: block;

		.listing-heading {
			margin: 15px 0;
			font-size: 23px;
			font-family: @font-family-sans-serif;

			a {
				color: @black;
			}
		}

		.listing-summary {
			font-size: 18px;
			font-weight: 200;
			margin-bottom: 20px;
		}
	}

	&:last-child {
		border-bottom: 0;
	}
}

// Metadata at the bottom of an article/page
// ------------------------------------
.meta {
	display: block;
	font-size: 13px;
	padding: 10px;
	margin: 20px 0;
	background-color: @gray-lighter;

	ul {
		margin: 0;
		list-style: none;
		float: right;
	}
	li {
		float: left;
		text-align: right;
		margin: 5px 0 5px 0;
		padding: 0 2px;
	}

	.cat {
		margin-top: 11px;

		&:before {
			content: '';
			.glyphicon;
			.glyphicon-tag;
			display: inline-block;
			margin-right: 5px;
		}
	}
}

.social-share-list{
	// list-style: none;
	// margin: 0px;
	// padding: 0px;

	// li {
	// 	list-style: none;
	// 	margin: 0px;
	// 	display: inline-block;

	.total {
		font-size: 14px;
	}

		// i {
		// 	margin-right: 5px;
		// }

		.btn {
			border: 0;
			margin-right: 5px;
		}
	// }
}

#breadcrumb {
	background: @gray-light;
	padding: 0;
	margin: 0;

	.breadcrumb {
		margin: 5px 0;
		padding: 0;
		background-color: transparent;
		font-size: 12px;
		color: @gray;

		a {
			color: @gray;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}



h1:first-child {
	margin-top: 0;
}

.page-heading {

	margin-bottom: 10px;
	h1 {
		font-weight: normal;
		font-family: @font-family-sans-serif;
		font-size: 50px;
		color: @gray;
	}

	@media (min-width: @screen-md) {
		h1 {
			font-size: 54px;
		}
	}
}


// Metadata at the top of an article/page ie. published date
// ------------------------------------
.media-meta {
	list-style: none;
	padding: 0;

	margin-bottom: @line-height-base;
	font-size: @font-size-small;
	color: @gray;

	.date {
		font-weight: bold;
	}

	li {
		display: block;
		float: left;
		padding-right: 20px;
	}
	.addthis_toolbox {
		width: 120%;
		.addthis_counter.addthis_bubble_style {
			width: 36px !important;
		}
	}
	.clearfix;
}

/* SEARCH BUTTONS */

#submit {
	background: url('../img/new-search-icon.png');
	width: 20px;
	height: 20px;
	border: none;
}


.listing_grid {
	li {
		margin-right: 20px;
		margin-bottom: 20px;
	}
}

// Landing page framework
// -------------------------
.grid_item {

	&.border_top {
		margin-top: 0;
		padding-top: 9px;
		border-top: 1px solid #d5d5d5;
	}

	&.border_bottom {
		margin-bottom: 0;
		padding-bottom: 9px;
		border-bottom: 1px solid #d5d5d5;
	}

	&.border_left {
		border-left: 1px solid #d5d5d5;
		padding-left: 9px;
		margin-left: 0;
	}

	&.border_right {
		border-right: 1px solid #d5d5d5;
		margin-right: 0;
		padding-right: 9px;
	}

	&.bg_tint {
		.box {
			margin-bottom: 20px;
		}
	}

}

// Video container for inline videos
// -------------------------
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Articles
// -------------------------

.article {
	
	h1, h2, h3, h4, h5, h6 {
		font-weight: 200;
	}
}

#downloads-accordion {
	border-top: 1px @gray-light solid;

	.panel-default {
		border-width: 0 0 1px 0;
		border-color: @gray-light;

		.panel-heading {
			padding: 5px;
			background-color: transparent;

			.panel-title {
				> a {
					display: block;
					font-family: @font-family-sans-serif;
					font-weight: 200;
					font-size: 14px;

					.fa-stack-2x {
						color: @gray-light;
					}

					&:hover, &:active, &:focus {
						text-decoration: none;
					}
				}
			}
		}
	}

	.panel {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.panel + .panel {
		margin-top: 0px;
	}
}

// Images
// -------------------------

.article-image {
  padding:0; // We need to override the grid padding on images, e.g. <img class="img article-image col-sm-6">
  margin-top:4px; // adjust to ensure top of image in line with top of text
}


.caption {
	margin-top: 5px;
	font-weight: bold;
	display: block;
	margin-bottom: 10px;

	font-size: @font-size-small;

	p {
		margin-bottom: 0;
		line-height: 13px;
	}

	.name {
		background-color: rgba(red(@st-blue), green(@st-blue), blue(@st-blue), .8);
		color: @white;
		font-size : 21px;
		font-weight: 200;
		padding: 0 8px;
		line-height: 1.4;

		sup {
			top: -0.2em;
		}
	}

	.title {
		line-height: 26px;
		padding: 0 7px;
		background-color: rgba(red(@white), green(@white), blue(@white), .7);
		display: inline-block;
		color: @gray-darker;
	}
}

.image-group-grid {

	.col-sm-6:nth-child(odd) {
		padding-left:0;
	}
	.col-sm-6:nth-child(even) {
		padding-right:0;
	}
	.clearfix;

}
// any article images mobile (xs) do not have floats and scale 100%)

@media (max-width: @screen-xs-max) {

	.article-image {
		&.float-left {float:none; margin:0}
		&.float-right {float:none; margin:0}
	}

	.image-group-grid {
		.img {
			float:none;
			margin:0;
			padding:0 !important;
		}
	}
}


// Responsive Video in Jquery Cycle

.cycle-sentinel iframe {
	opacity: 0;
}

// Added as part of responsive video work
// Don't think it should apply generally

.cycle-slideshow {
	
	&.video-slideshow {
		max-width: 770px;
		margin: auto;

		> div { width: 100%; height: 100% }
		> img { width: 100%; height: 100% }
		iframe,object, embed { width: 100%; height: 100% }
	}
	
}

// Read more buttons
// -------------------------
.readmore {
	.label;
	font-size: @font-size-small;
	background: @gray-dark;
	padding: 2px 3px;

	&:hover {
		background-color: darken(@gray-dark, 10%);
		text-decoration: none;
		color: white;
	}

	// Reverse out if on dark colour
	&.inverse {
		background: #fff;
		color: @gray-dark;
	}

	&.inverse:hover {
		color: @gray-darker;
	}
}

.file-download {
	margin: 0 !important;
	li {
		width: 180px;
		margin-right: 20px;
		margin-bottom: 20px;
		text-align: center;
		background: @gray-light;
		border: 1px solid #CCC;
		padding: 5px;

		img {
			margin: 0 auto;
			display: block;
		}
		.pdf-title {
			font-size: 14px;
			font-family: @headings-font-family;
			font-weight: 700;

			.pdf-icon {
				background: url('../img/pdf-icon.png');
				width: 15px;
				height: 20px;
				margin-right: 5px;
				display: inline-block;
				margin-bottom: -3px;
			}
		}
	}
}


// Pull quotes

.panel.pullquote {
	.quote {
		font-weight:bold;
		display:block;
	}
	.attribution {
		font-style:oblique;
		display:block;
	}

}

// Site Map
// ----------------------
.site-map {
	.site-map-list {
		li {
			width: 80px;
			margin-right: 20px;
			font-size: 20px;
			a {
				color: @text-color;
			}
		}
		li.last_child {
			margin-right: 0;
		}
	}
	.site-map-list-secondary {
		li {
			margin-top: 10px;
			font-size: 16px;
			a {
				color: @gray;
			}
		}
	}
}


.tab-content {
	.page-heading {
		margin-top: 25px; 
	}
}

/**	----------------------------------------
/**	3. PAGE SPECIFIC
/** Non-modular, non-repeatable elements
/**	----------------------------------------*/

body.donate {
	.label {
		font-family: @font-family-sans-serif-alt;
		font-weight: normal;
		text-transform: uppercase;
	}
}

.choices {

	> div:nth-child(odd) {
		background-color: #EFEFEF !important;
	}

	> div:nth-child(even) {
		background-color: #80CCCC !important;
	}

}

.cycle-slideshow {
	width: 100%;

	.slide {
		max-width: 100%;
	}
	.slide {
		display: none;
	}
	.slide:first-child,
	.cycle-slide {
		display: block;
	}
}

.gallery {
	.slider-nav {
		.slick-slide {
			img {
				height: 60px;
				width: 60px;
			}
		}

		.slick-prev, .slick-next {
			position: absolute;
			top: -50px;
			background: @st-blue;
			border: 0;
			color: @white;
			height: 50px;
			width: 100px;
			font-size: 20px;
		}

		.slick-prev {
			content: "\f104";
			left: 0;
		} 
		.slick-next {
			content: "\f105";
			right: 0;
		}
	}

	.caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		color: #fff;
		width: 100%;
	}

	@media (min-width: @screen-sm) {
		.slider-nav {
			.slick-slide {
				img {
					height: 90px;
					width: 90px;
				}
			}
		}
	}

	@media (min-width: @screen-md) {
		.slider-nav {
			.slick-slide {
				img {
					height: 130px;
					width: 130px;
				}
			}
		}
	}

	@media (min-width: @screen-lg) {
		.slider-nav {
			.slick-slide {
				img {
					height: 160px;
					width: 160px;
				}
			}
		}
	}
}

.glyphicon {
	.glyphicon;
}
.glyphicon-list {
	.glyphicon-list;
}

// Ensure we have reasonably tall pages no matter what.

#content {
	min-height:600px;
}

.inside-wrap {
	margin-top: 30px;
	margin-bottom: 60px;
}

.summary {
	font-size: 21px;
	line-height: 30px;
	color: @st-red;
}

body.news-entry {
	.page-heading {
		h1 {
			font-size: 42px;
		}
	}
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm) {

	#school-menu {
		display: block;
	}

	.navbar-default {
		.container {
			padding: 0 15px;
		}


		.burger-button {
			margin: 0px;
		}
	}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg) {

}

.fsEmbed span{
	font-weight: 700;
}

#landing-ad.white-popup{
	position: relative;
	padding: 50px 30px;
	width: auto;
	max-width: 80%;
	margin: 20px auto;
	background-color: #fff;

	img{
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.twitter_feed{
	overflow-x: scroll;
}

.banner{
	padding: 50px;
	@media(max-width:346px){
		padding: 20px;
	}
	background: url('../img/St-Stithians-Header.jpg') center no-repeat;
	height: 186px;
	@media(max-width:986px){
		height: auto;
	}

	img.img-responsive.logo-full {

		@media(max-width:986px){
			width: 250px;
		}

	}

	.banner_heading{
		font-family: @font-family-sans-serif;
		color: @st-blue;
		border-right: 1px @st-blue solid;
		text-transform: uppercase;
		//height: 151px;
		margin-top: -33px;
		//margin-bottom: 80px;

		
		@media(max-width:986px){
			border-right: none;
		}
		@media(max-width:462px){
			height: 230px;
		}

		@media(max-width:390px){
			height: 271px;
		}

		.title{
			font-size: 33px;
			font-weight: 900;
			padding-top: 33px;
			
		}
		.newsletter{
			font-size: 33px;
			font-weight: 300;	
		}
	}
}
.footer-message{
	padding: 0 15px;
}

#footer.footer-message{
	background: transparent;
	margin-top: -40px;
	.whois-footer{
		background: #4e4e4e;
	}
}
.message{
	font-family: @font-family-sans-serif;
	padding: 15px;
	.welcome-message,.index,.calendar{
		background: @gray-light;
		color: @st-blue;
		
		padding: 15px 15px 0px;
		//margin-bottom: 15px;
		.head-image{
			min-height: 315px;
			max-height: 315px;
			width: 250px;
		}
		.welcome-text{
			
			display: block;
			//display: -webkit-box;
			//max-width: 400px;
			//height: 120px;
			margin: 0 auto;
			-webkit-line-clamp: 3;
			//-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.welcome-title,.index-title{
			font-size: 26px;
			font-weight: 900;
			padding: 15px 0;
		}
		.index-title{
			padding: 0;
			font-size: 18px;
			margin-bottom: 20px;
		}
	}
	.impo-info{
		.heading-info{
			padding: 15px 0;
			color: @st-blue;
			font-size: 18px;
			font-weight: 300;
			text-transform: uppercase;
			text-align: center;
		}
		.line{
			padding-top: 5px;
			hr{
				border-bottom: 1px solid @st-blue;
				//width: 90%;
			}
		}
		
	}

	#footer{
		
		background: @gray-light;
		margin: -20px 0;
		font-size: 16px;
		font-weight: 900;
		padding: 0 25px;
		
		font-size: 15px;
	
		.footer-heading,h6{
			color: @st-blue;
			font-weight: 900;
		}
		.footer-address,p{
			color: @st-blue;
			font-weight: 300;
		}
	}
	
	.calendar{
		background: transparent;
		padding: 15px 0;
		.calHeading{
			background: @st-red;
			border:1px #fff solid;
			padding: 10px;
			color: #fff;
			font-weight: 300;
			text-transform: uppercase;
			font-size: 18px;
			text-align: center;
		}
	}
	.seven-cols{
		margin:0;
		.col-md-1 {
			border:1px solid #fff;
		}
		
		.calRow{
			background:@st-blue;
			color: #fff;
			padding: 10px;
			.fixtures{
				padding: 10px 0;
				color: @st-yellow;
				&-title{	
					font-size: 14px;
					font-weight: 700;
				}
				&-content{
					font-size: 14px;
					font-weight: 300;

				}
				
			}
		}
	}
	
	
}

.no-pad-left{
	padding-left: 0px;
	@media(max-width:988px){
		padding-left: 15px;
	}
}
.no-pad-right{
	padding-right: 0px;
	@media(max-width:988px){
		padding-right: 15px;
	}
}

.no-pad{
	padding-right: 0px;
	padding-left: 0px;

}

@media (min-width: 768px){
	.seven-cols .col-md-1,
	.seven-cols .col-sm-1,
	.seven-cols .col-lg-1  {
		width: 100%;
		*width: 100%;
	}
}
#shape{
	position: relative;
	font-family: @font-family-sans-serif;
	margin-bottom: 20px;
	.back, .front{
		position: absolute;
		width: auto;
		height: auto;
		background: #8734f7;
		-webkit-transform: skew(168deg);
		-moz-transform: skew(168deg);
		-o-transform: skew(168deg);
		transform: skew(168deg);
		padding: 5px;
		z-index: 5;
		top: -9px;
	}
	.front{
		left: 46px;
		min-width: 190px;
		max-width: 380px;
		padding-right: 20px;
		&.Sport{
			background: @st-green;
		}
		&.Achievements{
			background: @st-yellow;
		}
		&.Culture{
			background: @st-pink;
		}
		&.News{
			background: @st-lightblue;
		}
		&.Church{
			background: @st-orange;
		}
		&.Academics{
			background: #9d0ea8;
		}
		&.Transformation{
			background: #11eadf;
		}
		&.Community-engagement{
			background: #f7d478;
		}
		&.Events{
			background: #5e0639;
		}
		&.PTA-Corner{
			background: #e21130;
		}
		&.Staff-Development{
			background: #04bd91;
		}
		&.Tuckshop{
			background: #97d206;
		}
		&.Relevant-reads{
			background: #e92057;
		}
		&.Environment{
			background: #e92057;
		}
		&.Partners{
			background: #106277;
		}
		&.Round-square{
			background: #52b5f3;
		}

		&.Grades-notice{
			background: #ebb400;
		}

		&.Grades{
			background: #664a23;
		}

		&.Career-Corner{
			background: #438959;
		}

		&.Leadership{
			background: #c63622;
		}

		&.App-of-the-Week{
			background: #103eff;
		}

		&.Green-Corner{
			background: #3c8e10;
		}

		&.Parent-tip{
			background: #e2d820;
		}

		&.Library{
			background: #3f1d0b;
		}

		&.Staff{
			background: #db0e70;
		}

		&.Thinking-skills{
			background: #43c423;
		}
		
		.icon-theme{
			&-Sport,&-Grades-notice,&-Grades,&-Career-Corner,&-Leadership,&-Culture,&-Achievements,&-News,&-Church,&-Academics,&-Transformation,&-Community,&-Community-engagement,&-Events,&-PTA-Corner,&-Staff-Development,&-Tuckshop,&-Relevant-reads,&-Environment,&-Partners,&-Round-square,&-App-of-the-Week,&-Green-Corner,&-Parent-tip,&-Library,&-Staff,&-Thinking-skills{
				
				transform: skew(12deg);
				display: inline-block;
				font-size: 20px;
				margin-right: 5px;
				height: 20px;
				width: 20px;
				position: relative;
				top: 4px;
				margin-left: 10px;
			}

			&-App-of-the-Week{
				content: url('../img/App_of_the_week.svg');
			}

			&-Green-Corner{
				content: url('../img/green_corner.svg');
			}

			&-Parent-tip{
				content: url('../img/parent_tip.svg');
			}

			&-Library{
				content: url('../img/Library.svg');
			}

			&-Staff{
				content: url('../img/Staff.svg');
			}

			&-Thinking-skills{
				content: url('../img/Thinking_skills.svg');
			}
			
			&-Sport{
				content: url('../img/sport.svg');
				
			}

			&-Achievements{
				content: url('../img/Achievments.svg');
				
			}

			&-Culture{
				content: url('../img/culture.svg');
				
			}

			&-News{
				content: url('../img/news.svg');
				
			}

			&-Church{
				content: url('../img/church.svg');
				
			}

			&-Academics{
				content: url('../img/Academics.svg');
				
			}

			&-Transformation{
				content: url('../img/transformation.svg');
				
			}


			&-Community-engagement{
				content: url('../img/community engage.svg');
				
			}

			&-Events{
				content: url('../img/events.svg');
				
			}

			&-PTA-Corner{
				content: url('../img/PTA Corner.svg');
				
			}

			&-Staff-Development{
				content: url('../img/Staff development.svg');
				
			}

			&-Tuckshop{
				content: url('../img/tuckshop.svg');
				
			}

			&-Relevant-reads{
				content: url('../img/relevant reads.svg');
				
			}
			&-Environment{
				content: url('../img/environment.svg');
				
			}

			&-Partners{
				content: url('../img/partners.svg');
				
			}
			&-Grades{
				content: url('../img/Grades.svg');
				
			}
			&-Grades-notice{
				content: url('../img/Grades_notice.svg');
				
			}
			&-Round-square{
				content: url('../img/round_square.svg');
				
			}
			&-Career-Corner{
				content: url('../img/career_corner.svg');
				
			}
			&-Leadership{
				content: url('../img/Leadership.svg');
				
			}
		}
		
		.iconText{
			&-Sport,&-Culture,&-Achievements,&-News,&-Church,&-Academics,&-Transformation,&-Community,&-Community-engagement,&-Events,&-PTA-Corner,&-Staff-Development,&-Tuckshop,&-Relevant-reads,&-Environment,&-Partners,&-Events,&-Grades,&-Grades-notice,&-Round-square,&-Career-Corner,&-Leadership,&-App-of-the-Week,&-Green-Corner,&-Parent-tip,&-Library,&-Staff,&-Thinking-skills{
				.front-heading{
					text-transform: uppercase;
					font-size: 16px;
					transform: skew(12deg);
					font-weight: 400;
					display: inline-block;

				}
			}

			&-Sport,&-Culture,&-Relevant-reads,&-Academics,&-Events,&-PTA-Corner,&-Partners,&-Environment,&-Grades,&-Grades-notice,&-Round-square,&-Career-Corner,&-Leadership,&-App-of-the-Week,&-Green-Corner,&-Parent-tip,&-Library,&-Staff,&-Thinking-skills{
				
				color:#fff;
				
				
			}
			&-Transformation,&-Staff-Development,&-Community-engagement,&-Tuckshop{
				color:@st-blue;
				
			}
			&-Achievements,&-News,&-Church{
				
				color:@st-blue;
				
			}



			.icon{
				padding-right: 10px;
				img{
					width: 16px;
					height: 16px;
					margin: -5px 0 0px;
				}

			}
			
		}
		
		
		
	}
	.back{
		left: 43px;
		background: darken(#8734f7, 40%) ;
		z-index: 3;
		-webkit-transform: skew(131deg);
		-moz-transform: skew(131deg);
		-o-transform: skew(131deg);
		transform: skew(131deg);

		&.App-of-the-Week{
			background: darken(#103eff, 40%);
			}

			&.Green-Corner{
				background: darken(#3c8e10, 40%);
			}

			&.Parent-tip{
				background: darken(#e2d820, 40%);
			}

			&.Library{
				background: darken(#3f1d0b, 40%);
			}

			&.Staff{
				background: darken(#db0e70, 40%);
			}

			&.Thinking-skills{
				background: darken(#43c423, 40%);
			}

		&.Sport{
			background: darken(@st-green, 40%);
		}
		&.Achievements{
			background: darken(@st-yellow, 40%);
		}
		&.Culture{
			background: darken(@st-pink, 40%);
		}
		&.News{
			background: darken(@st-lightblue, 40%);
		}
		&.Church{
			background: darken(@st-orange, 40%);
		}
		&.Academics{
			background: darken(@st-orange, 40%);	
		}

		&.Transformation{
			background: darken(@st-orange, 40%);

		}

		&.Community{
			background: darken(@st-orange, 40%);

		}

		&.Community-engagement{
			background: darken(@st-orange, 40%);

		}

		&.Events{
			background: darken(@st-orange, 40%);

		}

		&.PTA-Corner{
			background: darken(@st-orange, 40%);

		}

		&.Staff{
			background: darken(@st-orange, 40%);

		}

		&.Staff-Development{
			background: darken(@st-orange, 40%);

		}

		&.Tuckshop{
			background: darken(@st-orange, 40%);

		}

		&.Relevant-reads{
			background: darken(@st-orange, 40%);

		}

		&.Environment{
			background: darken(@st-orange, 40%);

		}

		&.Partners{
			background: darken(@st-orange, 40%);

		}

		&.Grades{
			background: darken(#664a23, 40%);

		}
		&.Grades-notice{
			background: darken(#ebb400, 40%);


		}
		&.Round-square{
			background: darken(#52b5f3, 40%);


		}
		&.Career-Corner{
			background: darken(#438959, 40%);

		}
		&.Leadership{
			background: darken(#c63622, 40%);
			color:#c63622;

		}

		
	}

	.content{
		position: relative;
		background:@gray-light;
		color: @st-blue;
		
		z-index: 4;
		margin-bottom: 20px;
		padding: 25px 0px;

		.full_feature_img{
			margin-top: -25px;
		}
		.half_feature_img{
			margin-top: -25px;
			height: 300px;
			overflow: hidden;
			padding-bottom: 20px;
			@media (max-width:550px) {
				height: auto;
				padding-bottom: 0px;
			}

		}
		.half_feature_img-subheading{
			margin: 15px 0 15px;
			
			font-size: 16px;
			font-weight: 900;
			padding: 0 25px;
		}

		.subheading{
			margin: 30px 0 15px;
			
			font-size: 16px;
			font-weight: 900;
			padding: 0 25px;
		}

		.contentInfo{

			padding: 0 25px 10px;
			display: block;
			display: -webkit-box;
			//max-width: 400px;
			//height: 80px;


			&-short{
				height: 100px;
				margin: 0 auto;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				@media(max-width:568px){
					height: 100%;
				}
			}

			&-long{
				height: 180px;
				@media(max-width:568px){
					height: 100%;
				}
			}

		}

	}

}


.impo-info{
	margin-bottom: 20px;
}

@media (min-width: 992px) {
	.seven-cols .col-md-1,
	.seven-cols .col-sm-1,
	.seven-cols .col-lg-1 {
		width: 14.285714285714285714285714285714%;
		*width: 14.285714285714285714285714285714%;
	}
}

/**
 *  The following is not really needed in this case
 *  Only to demonstrate the usage of @media for large screens
 */    
 @media (min-width: 1200px) {
 	.seven-cols .col-md-1,
 	.seven-cols .col-sm-1,
 	.seven-cols .col-lg-1 {
 		width: 14.285714285714285714285714285714%;
 		*width: 14.285714285714285714285714285714%;
 	}
 }
 .filter {
 	display: inline-block;
 	position: relative;
 	overflow: hidden;
 	height: 28px;
 	width: 100%;
 	background: #f2f2f2;
 	border: 1px solid;
 	border-color: white #f7f7f7 whitesmoke;
 	border-radius: 3px;
 	background-image: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.06));
 	background-image: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.06));
 	background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.06));
 	background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.06));
 	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
 	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
 }

 .filter:before, .filter:after {
 	content: '';
 	position: absolute;
 	z-index: 2;
 	top: 9px;
 	right: 10px;
 	width: 0;
 	height: 0;
 	border: 4px dashed;
 	border-color: #888888 transparent;
 	pointer-events: none;
 }

 .filter:before {
 	border-bottom-style: solid;
 	border-top: none;
 }

 .filter:after {
 	margin-top: 7px;
 	border-top-style: solid;
 	border-bottom: none;
 }

 .filter-select {
 	position: relative;
 	width: 130%;
 	margin: 0;
 	padding: 6px 8px 6px 10px;
 	height: 28px;
 	line-height: 14px;
 	font-size: 12px;
 	color: #62717a;
 	text-shadow: 0 1px white;
 	background: #f2f2f2; /* Fallback for IE 8 */
 	background: rgba(0, 0, 0, 0) !important; /* "transparent" doesn't work with Opera */
 	border: 0;
 	border-radius: 0;
 	-webkit-appearance: none;
 }

 .filter-select:focus {
 	z-index: 3;
 	width: 100%;
 	color: #394349;
 	outline: 2px solid #49aff2;
 	outline: 2px solid -webkit-focus-ring-color;
 	outline-offset: -2px;
 }

 .filter-select > option {
 	margin: 3px;
 	padding: 6px 8px;
 	text-shadow: none;
 	background: #f2f2f2;
 	border-radius: 3px;
 	cursor: pointer;
 }

 /* Fix for IE 8 putting the arrows behind the select element. */

 .lt-ie9 .filter {
 	z-index: 1;
 }

 .lt-ie9 .filter-select {
 	z-index: -1;
 }

 .lt-ie9 .filter-select:focus {
 	z-index: 3;
 }

 /* Dirty fix for Firefox adding padding where it shouldn't. */

 @-moz-document url-prefix() {
 	.filter-select {
 		padding-left: 6px;
 	}
 }

 .filter-dark {
 	background: #002458;
 	border-color: #111111 #0a0a0a black;
 	background-image: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
 	background-image: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
 	background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
 	background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
 	-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
 	box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
 }

 .filter-dark:before {
 	border-bottom-color: #aaa;
 }

 .filter-dark:after {
 	border-top-color: #aaa;
 }

 .filter-dark .filter-select {
 	color: #aaa;
 	text-shadow: 0 1px black;
 	background: #444;  /* Fallback for IE 8 */
 }

 .filter-dark .filter-select:focus {
 	color: #ccc;
 }

 .filter-dark .filter-select > option {
 	background: #444;
 	text-shadow: 0 1px rgba(0, 0, 0, 0.4);
 }

 .filter-title{
 	font-size: 18px;
 	font-weight: 900;
 	padding: 15px 0;
 	color: @st-blue;
 }


 .select select {
  display: block;
  position: relative;
  background: none;
	appearance: none;
}

.input-icon {
  position: relative;
  &:after,
  &:before {
    .fa-icon();
    position: absolute;
    z-index:1;
    top: ~'calc(50% - 7px)';
    color: @gray;
  }
  
  &.left { 
    &:before {
      left: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control {
      padding-left: 2.2em;
    }
    
    &-auto:before { left: auto; }
  }
  
  &.right { 
    &:after {
      right: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control {
      padding-right: 2.2em;
    }
    &-auto:after { right: auto; }
  }
  
  > .dropdown,
  > .form-control {
    &:focus, &:active {
      outline: none;
    }
  }
}

.input-icon.search.left:before { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.select.left:before { content: @fa-var-angle-down; }
.input-icon.select.right:after { content: @fa-var-angle-down; }

.input-icon-spinner { margin-top: -11px; margin-right: 9px; z-index: 4; }

.control-label + .help-block {
	margin-top: -5px;
	margin-bottom: 5px;
}


// ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------
// Give to St Stithians - August 2020
// ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// Variables
// -------------------------

@triangle-size: 		11px;
@triangle-size-top: 	8px;

// Containers
// -------------------------

.container {
	&-md {
		max-width: @container-desktop;
	}
	&-lg {
		max-width: @screen-lg;
	}
}

// Position
// -------------------------


.static	{position: static !important; }
.fixed	{position: fixed !important; }
.absolute	{position: absolute !important; }
.relative	{position: relative !important; }
.sticky	{position: sticky !important; }

// Z-index
.z-0	{ z-index: 0; }
.z-10	{ z-index: 10; }
.z-20	{ z-index: 20; }
.z-30	{ z-index: 30; }
.z-40	{ z-index: 40; }
.z-50	{ z-index: 50; }
.z-auto	{ z-index: auto; }

// Paragraphs
// -------------------------

.line-height {
	&-base {
		line-height: @line-height-base;
	}
	&-headings {
		line-height: @headings-line-height;
	}
	&-1 {
		line-height: 1em;
	}
	&-2 {
		line-height: 2em;
	}
	&-loose {
		line-height: 1.5em;
	}
}

// Shadows
// -------------------------

.text-shadow {
	text-shadow: 0 2px 4px rgba(black, .5);
	&-sm {
		text-shadow: 0 3px 3px rgba(black, .33);
	}
}

.shadow-sm {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.shadow {
	box-shadow: 0 2px 4px rgba(black, .33);
}
.shadow-md {
	box-shadow: 0 4px 10px rgba(black, .5);
}
.shadow-lg {
	box-shadow: 0 2px 20px rgba(black, .2);
}

// SVG filters
// -------------------------

.filter-shadow {
	filter: drop-shadow(0 4px 2px rgba(0,0,0,.33));
}

// Text colours / sizes / transforms
// -------------------------

.text {
	&-white {
		color: @white !important;
	}
	&-red {
		color: @st-red !important;
	}
	&-blue {
		color: @st-blue !important;
	}
	&-uppercase {
		text-transform: uppercase;
	}
	&-small {
		font-size: @font-size-small;
	}
}

// Background colours
// -------------------------

.bg {
	&-red {
		background-color: @st-red !important;
		&.title {
			font-family: @font-family-sans-serif;
			text-align: center;
			padding: 10px 20px 7px;
			text-transform: uppercase;
		}
	}
	&-blue {
		background-color: @st-blue !important;
	}
	&-white {
		background-color: @white !important;
		&-alpha {
			background-color: rgba(#fff, .4);
		}
	}
}

// Forms
// -------------------------

.form-control { height: auto; }

.checkbox input[type=checkbox] { top: 4px; }

label { vertical-align: middle; }


// Fonts
// -------------------------

.text-xs	{ font-size: 0.75em; line-height: 1em; }
.text-sm	{ font-size: 0.875em; line-height: 1.25em; }
.text-base	{ font-size: 1em; line-height: 1.5em; }
.text-lg	{ font-size: 1.125em; line-height: 1.75em; }
.text-xl	{ font-size: 1.25em; line-height: 1.75em; }

.font-family-sans-serif {
	font-family: @font-family-sans-serif;
}


.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }

.larger-fonts {
	font-size: 17px;
	font-weight: 200;
	.h4, h4 {
		font-size: 1.1em;
	}
	.h5, h5 {
		font-size: 1.2em;
		font-weight: 500;
	}
	p {
		margin-bottom: 1em;
		small {
			display: block;
			line-height: 1.5em;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	section .title {
		margin-top: 0;
	}
	input {
		font-size: 1em;
	}
}

.give-styles section {
	margin-bottom: @grid-gutter-width*1.5;
}

// Welcome section
// -------------------------

.give-styles #panel-welcome {
	background: url('../img/placeholder-campus-colour.jpg') center center no-repeat;
	background-size: cover;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .2;
		background: linear-gradient(180deg, #000000 0%, rgba(1,1,1,0.6) 18.84%, rgba(2,2,2,0) 100%);
	}
	.row {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		position: relative;
		> div {
			float: none !important;
			padding: 5px 15px;
			&.bg-white-alpha {
				margin: 0 15px;
				padding: 12px 30px;
			}
			&:first-of-type {
				margin-right: auto;
				padding: 45px 15px;
			}
			&:last-of-type {
				margin-left: auto;
			}
		}
	}
}

.header-main {
	position: sticky;
	top: 0;
	width: 100%;
	display: block;
	margin: 0 auto;
	z-index: 999;
	box-shadow: 0 2px 5px rgba(#000, .3);
}

#header {
	padding-top: 10px;
}

// Buttons
// -------------------------

.give-styles .btn {


	&-red {
		border-radius: 4px;
		background-color: @st-red;
		border: 2px solid @st-red;
		color: @white;
	}
	&-outline {
		&-red {
			border-radius: 4px;
			color: @st-red;
			border: 2px solid @st-red;
			background-color: @white;

			&.active {
				background-color: @st-red;
				color: #fff;
			}
		}
		&-blue {
			border-radius: 4px;
			color: @st-blue;
			border: 2px solid @st-blue;
			background-color: @white;
			&.active {
				background-color: @st-blue;
				color: #fff;
			}
		}
	}
	&-give {
		// transition: background-color .2s linear, border .2s linear;
		&:hover {
			background-color: @st-red;
			color: @white;
			border-color: @st-red;
		}
		&.btn-lg {
			padding: 7px 20px;
			font-size: 16px;
		}
	}
}

.give-styles #panel-message {
	background-color: @st-blue;
	p {
		font-weight: 300;
		font-size: 1em;
		line-height: 1.6em;
		margin-bottom: 1.6em;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.section-indicator {
		left: auto;
		top: auto;
		bottom: 100%;
		margin-bottom: 15px;
		right: 100%;
		position: absolute;
	}
}

.main-indicator {
	left: @grid-gutter-width/2;
	top: auto;
	bottom: 100%;
	margin-bottom: 6px;
	position: absolute;
}

// Panels / Cards / Flexbox
// -------------------------

.d-flex {
	display: flex;
	flex-wrap: wrap;
	.panel {
		&-card {
			position: relative;
			margin: 0 auto 30px;
			float: none;
			border: 0;
			max-width: 320px;
			width: calc(80% - @grid-gutter-width);
			@media (min-width: @screen-xs) {
				width: calc(66.67%);
				margin: 0 10px 45px;
			}
			@media (min-width: @screen-sm) {
				width: 33.33%;
				margin: 0 10px 45px;
			}
			@media (min-width: @screen-md) {
				width: calc(33.33% - @grid-gutter-width);
			}
			@media (min-width: @screen-lg) {
				width: calc(25% - @grid-gutter-width);
			}
			&-large {
				text-align: center;
				@media (min-width: @screen-lg) {
					width: calc(33.33% - @grid-gutter-width);
					margin: 0 15px 45px;
				}
				.panel-title {
					font-size: 1.2em;
				}
			}
		}
		&:before {
			content: '';
			position: absolute;
			top: -2px;
			left: -2px;
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			border: 0 solid @st-blue;
			z-index: 0;
		}
		&:hover {
			.panel-title {
				color: @st-red;
			}
			.btn {
				color: @white;
				border-color: @st-red;
				background-color: @st-red;
			}
			&:before {
				border-width: 2px;
			}
		}
		&-heading {
			padding: 0;
			background-color: @gray-light;
		}
		&-title {
			margin: 10px 0;
			font-size: 1.05em;
			font-weight: 300;
			color: @st-blue;
		}
		&-body {
			flex-grow: 1;
			position: relative;
			line-height: 1em;
			padding: 5px 20px 0;
			.section-indicator {
				position: absolute;
				margin-bottom: -7px;
				bottom: 100%;
				left: 0;
				.label {
					font-size: .8em;
					line-height: 31px;
					margin: auto;
					display: block;
					text-align: center;
					width: 100%;
				}
			}
			p {
				margin-bottom: 0;
				font-size: 70%;
			}
		}
		&-footer {
			text-align: center;
			background: none;
			border: 0;
			padding: 15px;
			.btn {
				margin: auto;
				position: relative;
			}
		}
	}
}

.w-25 {
	width: 	25% !important;
}

.w-50 {
	width: 	50% !important;
}

.w-73 {
	width: 	73% !important;
}

.w-full {
	width: 100% !important;
}

.py-035 {
	padding-top: 8px;
	padding-bottom: 8px;
}

.flex-1	{ flex: 1 1 0%; }
.flex-auto	{ flex: 1 1 auto; }
.flex-initial	{ flex: 0 1 auto; }
.flex-none	{ flex: none; }

.flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }
.block { display: block;}
.inline-block { display: inline-block;}

.flex-row	{ flex-direction: row !important; }
.flex-row-reverse	{ flex-direction: row-reverse !important; }
.flex-col	{ flex-direction: column !important; }
.flex-col-reverse	{ flex-direction: column-reverse !important; }

.justify-start	{ justify-content: flex-start !important; }
.justify-end	{ justify-content: flex-end !important; }
.justify-center	{ justify-content: center !important; }
.justify-between	{ justify-content: space-between !important; }
.justify-around	{ justify-content: space-around !important; }
.justify-evenly	{ justify-content: space-evenly !important; }

.items-start	{ align-items: flex-start !important; }
.items-end	{ align-items: flex-end !important; }
.items-center	{ align-items: center !important; }
.items-baseline	{ align-items: baseline !important; }
.items-stretch	{ align-items: stretch !important; }

.self-auto	{ align-self: auto !important; }
.self-start	{ align-self: flex-start !important; }
.self-end	{ align-self: flex-end !important; }
.self-center	{ align-self: center !important; }
.self-stretch	{ align-self: stretch !important; }

// Additional elements
// -------------------------

.mx-auto { margin-left: auto; margin-right: auto; }
.m-auto { margin: auto; }
.mr-auto { margin-right: auto !important; }
.ml-auto { margin-left: auto !important; }

.banner-footer {
	display: block;
	margin: 0 auto -40px;
	&.pull-up {
		margin-top: -40px;
	}
}

.carousel-control * {
	margin-top: 66.67%;
}

.rounded {
	border-radius: 4px;
}

.h-60x {
	height: 60px !important;
}

.border-1 {
	border-width: 1px !important;
}

// #homepageModal{
// 	display: block;
// 	z-index: 99999;
// 	.modal-body{
// 		margin-left: auto;
// 		margin-right: auto;
// 		margin-top: 20vh;
// 		width: fit-content;
// 	}
// 	.modal-image{
// 		width: 100%;
//   		height: auto;
// 		cursor: pointer;
// 	}
// 	.close {
// 		float: right;
// 		font-size: 21px;
// 		font-weight: 700;
// 		line-height: 1;
// 		color: #e01837;
// 		text-shadow: 0 1px 0 #fff;
// 		opacity: .6;
// 	}
// 	@media only screen and (max-width: 720px) {
// 		.modal-body {
// 			margin-top: 30vh;
// 		}
// 	}
// }