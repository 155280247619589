.ray-of-light {

    .cta-button {
        padding: 5px 15px;
        background-color: @st-red;
        color: #fff;
        border: 0;
        outline: 0;
        box-shadow: none;
    }

.content {
    font-family: @font-family-sans-serif;
    color: #1A2857;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;

    h1 {
        font-family: @font-family-sans-serif;
        color: @st-red;
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 35px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .lead {
        font-family: @font-family-sans-serif;
        font-size: 25px;
        letter-spacing: 0;
        line-height: 32px;
    }


}

.video-block {
    background: #f0f0f0;
    overflow: hidden;

    iframe {
        width:100%;
        height:420px; //lol
    }

    .title-block {
        display:none !important;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 5000px;
            background:@st-red;
            overflow: hidden;
        }

        color: @white;
        font-family: @font-family-sans-serif;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        background:@st-red;
        padding:20px;
        position: absolute;
        bottom: 15px;
        right: 0;
        width: 300px;

        h1 {
            font-family: @font-family-sans-serif;
            color: @white;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 35px;
            text-transform: uppercase;
            margin-bottom: 20px;
            position:relative;
        }

        p {
            text-transform: uppercase;
            position:relative;
        }

        @media (max-width:991px) {
            display:none;
        }
    }
}

.subheader {
    background: @st-blue;

    img.iconbadge {
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: 65px;
    }

    .title {
        font-size: 23px;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        z-index: 1;

        transition: opacity 200ms ease-in-out;
    }

    .hover {
        background: rgba(@st-blue, 0.8);
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        padding:15px;
        text-align: center;
        z-index: 10;

        transition: all 0.5s ease-in-out;
        opacity:0;
        transform: translateY(100%);

        p {
            font-size: 14px;
            margin-bottom: 15px;
            margin-top:10px;
        }
    }

    a {
        text-align: center;
        overflow: hidden;
        padding:15px;
        position: relative;
        color:#fff;
        height:230px;
        background-position: 50% 0;
        background-size: cover;

        text-decoration: none;

        &::before {
            content: '';
            background: rgba(@st-blue, 0.3);
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
        }

        &:hover {
            .title {
                opacity: 0;
            }
        }

        &:hover .hover {
            opacity:1;
            transform: translateY(0);

            .title {
                opacity: 1;
            }
        }

    }
}


.counter {
    background: @st-blue;
    color:#fff;
    padding:45px 0;
    margin-bottom: 45px;

    h1 {
        font-size: 35px;
        font-family: @font-family-sans-serif;
        text-transform: uppercase;
        margin-bottom:25px;
        color:@st-red;
    }

    .stats {
        // padding:0 30px;
        height:420px;
        @media (max-width:991px) {
            height:auto;
            border-top:1px solid #fff;
            padding: 25px 0;
            margin-top:25px;
        }
    }

    .details {
        @media (max-width:991px) {
            justify-content: space-around !important;
        }
    }

    .number {
        font-size: 75px;
        display: block;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 75px;
    }

    .stat + .stat{
        padding-left:55px;
    }


    .value {
        display: block;
    }
}


.faq {

    .panel-group {
        border-top: 1px solid #1A2857;
    }

    .panel {
        border-bottom: 1px solid #1A2857;
    }

    .panel-heading {
        padding:0;

        a {
            padding: 15px 15px;
            background: #f0f0f0;
            display:block;
            text-decoration: none !important;
        }

        .collapsed {
            background: #fff;
        }
    }

    .panel-title {
        font-family: @font-family-sans-serif;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        color: #1A2857;
    }

    h1 {
        font-family: @font-family-sans-serif;
        color: @st-red;
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 35px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .panel-body {
        font-family: @font-family-sans-serif;
        color: #1A2857;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
        border:0 !important;
        background: #f0f0f0;
        padding: 10px 15px 25px !important;
    }

    .panel-group .panel {
        margin:0 !important;
    }
}


.related-news {
    margin:45px 0;

    h1 {

        color: #1A2857;
        font-family: Avenir;
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 35px;
      }


    .listing-meta {
        font-weight: 500;
        font-size: 15px;
    }

    h2 {
        font-family: @font-family-sans-serif;
        font-weight: 600;
        font-size: 24px;
        
        a {
            color:#1A2857;
            text-decoration: none;
        }
    }
}

.rays-carousel {
    background: #f0f0f0;
    position: relative;

    .badge1  {
        position:absolute;
        top:30px;
        right:30px;
        width:100px;
        height:100px;
    }

    .badge2  {
        position:absolute;
        top:30px;
        right:145px;
        width:100px;
        height:100px;
    }

    svg {
        background: 0 0;
        position: absolute;
        opacity: .2;
        top: -56%;
        left: 51.5%;
        width: 100%;

        @media (max-width:1024px) {
            top: -10%;
            left: 60.5%;
        }

        .st0 {
            fill:#e0bf00;
        }
    }

    .holder {
        max-width: 60%;
        font-family: Avenir;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;

        @media (max-width:1024px) {
            max-width: 100%;
            padding:0 100px;
        }

        @media (max-width:730px) {
            max-width: 100%;
            padding:0 100px 30px;
        }

        @media (max-width:680px) {
            max-width: 100%;
            padding:0 30px 30px;
        }
    }

    .text-content {
        background: rgba(0,0,0,.5);
        position: absolute;
        padding-top:50px;
        top:0;
        left:0;
        bottom:0;
        right:0;
        overflow: hidden;

        @media (max-width:730px) {
            align-items: flex-end !important;
        }
    }

    h1 {
        font-size: 75px;
        font-family: Avenir;
        font-weight: 200;
        text-shadow: 1px 1px 5px rgba(0,0,0,0.5);

        @media (max-width: 1080px) {
            font-size: 60px;
        }

        @media (max-width: 720px) {
            font-size: 45px;
        }

        @media (max-width: 400px) {
            font-size: 40px;
        }

        .yellow {
            color:#e0bf00;
        }

        .bolder {
            font-weight: 600;
        }
    }

    .carousel-inner .item {
        @media (max-width:680px) {
            height: 600px;

            img {
                height:600px;
                object-fit: cover;
            }
        }
    }

    
    .carousel-control {
        background: none !important;
        opacity: 1 !important;
        width:100px;

        @media (max-width:680px) {
            display: none;
        }

        .fa {
            color: @st-red;
            margin:0;
            font-size:45px;
        }
    }

    .desktop-carousel {
        // height:500px;
        img {
            width:100%;
        }

        .item {
            background-size: cover;
            height: 500px;
            width: 100%;
            flex-grow: 1;
            background-position: center;
        }
    }


    @media (max-width:1024px) {
        .desktop-carousel {
            display:none;
        }
    }
    @media (min-width:1024px) {
        .carousel-indicators,
        .carousel-control,
        .carousel-inner {
            display:none;
        }
    }

}

.ticker {
    background: #e0bf00;
    padding:10px;
    text-transform:uppercase;
    color:#1A2857;
    font-size: 18px;
    font-weight: 400;
    font-family: Avenir;
    border-top:1px solid #fff;
    border-bottom:1px solid #fff;
    overflow: hidden;;
    

    .tape {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -moz-animation: scroll-left 10s linear infinite;
        -webkit-animation: scroll-left 10s linear infinite;
        animation: scroll-left 10s linear infinite;
        white-space: nowrap;

        span {
            display:inline-block;
            padding:0 45px;
        }
    }
}


@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

#progressbar, #progressbar2 {
    transition: all 1500ms ease-in-out;
}

}

.ray-panels {
    p {
        font-size: 14px !important;
        line-height: 1.5 !important;
    }

    @media (min-width: 1200px) {
        .panel-card {
            width: calc(33.33% - 30px) !important;
        }
    }

    // @media (min-width: 992px) {
    //     .panel-card {
    //         width: calc(% - 30px);
    //     }
    // }
}