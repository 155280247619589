.col-bdr, .col-bdl, .col-bdt, .col-bdb {
  position: relative;
}

.col-bdr:before,
.col-bdl:before,
.col-bdt:after,
.col-bdb:after {
  content: "";
  position: absolute;
  line-height: 1px;
  border: 0 solid #d5d5d5;
}


.col-bd-solid:before,
.col-bd-solid:after {
  border-style: solid;
}

.col-bdr:before,
.col-bdl:before {
  top: 0;
  height: 100%;
}

.col-bdb:after,
.col-bdt:after {
  left: 0;
  width: 100%;
}

.col-bdr:before {
  right: 0;
  border-right-width: 1px;
}

.col-bdl:before {
  left: 0;
  border-left-width: 1px;
}

.col-bdb:after {
  bottom: 0;
  border-bottom-width: 1px;
}

.col-bdt:after {
  top: 0;
  border-top-width: 1px;
}

.col-bdr + .col-bdl:before {
  left: -1px;
}

.col-bdl + .col-bdr:before {
  right: -1px;
}
.col-bdr + .col-bdr:before {
  right: 0px; // reset
}

@media (max-width: @screen-md-max) {
  .col-bdl-md-up:before {
    border-left-width: 0 !important;
  }

  .col-bdr-md-up:before {
    border-right-width: 0 !important;
  }

  .col-bd-md-up:before,
  .col-bd-md-up:after {
    border-width: 0 !important;
  }
}

@media (max-width: @screen-sm-max) {
  .col-bdl-sm-up:before {
    border-left-width: 0 !important;
  }

  .col-bdr-sm-up:before {
    border-right-width: 0 !important;
  }

  .col-bd-sm-up:before,
  .col-bd-sm-up:after {
    border-width: 0 !important;
  }
}


@media (max-width: @screen-xs-max) {
  .col-bd-xs-up:before,
  .col-bd-xs-up:after {
    border-width: 0 !important;
  }

  .col-bdl-xs-up:before {
    border-left-width: 0 !important;
  }
  .col-bdr-xs-up:before {
    border-right-width: 0 !important;
  }
}

@media (min-width: @screen-sm) {
  .col-bd-xs:before,
  .col-bd-xs:after {
    border-width: 0 !important;
  }
}